// import PropTypes from "prop-types";
import { createContext, useContext, useReducer } from 'react';

import { ActionType, IOptionType } from '@/common/constants';
import { useCategoryQueryQuery } from '@/controller/schema';

import { getLocalStorageItem, setLocalStorageItem } from '../util/LocalStorage';

export interface AppState {
  volunteer: any | undefined | null;
  volunteerList: any[];
  postList: any[];
  categories: IOptionType[];
  postMessage: string;
}
let initialAppState: AppState = {
  volunteer: undefined,
  volunteerList: [],
  postList: [],
  categories: [{ id: 1, name: 'medicine' }],
  postMessage: ''
};

export interface AppContextProps {
  appContextState: AppState;
}
export const APP_ACTIONS = {
  LOGGED_VOLUNTEER: 'LOGGED_VOLUNTEER',
  CATEGORIES: 'CATEGORIES',
  POST_MESSAGE: 'POST_MESSAGE',
  HOME_QUERY: 'HOME_QUERY',
  POST_LIST: 'POST_LIST',
  VOLUNTEER_LIST: 'VOLUNTEER_LIST'
};

// let initialAppState = getLocalStorageItem(LOCAL_STORAGE_APP_CONTEXT);

export { initialAppState };

const appReducer: React.Reducer<AppState, ActionType> = (
  state: AppState = initialAppState,
  action: ActionType
): AppState => {
  let newState;
  switch (action.type) {
    case APP_ACTIONS.HOME_QUERY:
      newState = {
        ...state,
        ...action.payload
      };
      setLocalStorageItem('localstate', newState);
      return newState;
    case APP_ACTIONS.LOGGED_VOLUNTEER:
      newState = {
        ...state,
        volunteer: action.payload
      };
      setLocalStorageItem('localstate', newState);
      return newState;
    case APP_ACTIONS.VOLUNTEER_LIST:
      newState = {
        ...state,
        volunteerList: action.payload
      };
      setLocalStorageItem('localstate', newState);
      return newState;
    case APP_ACTIONS.POST_LIST:
      newState = {
        ...state,
        postList: action.payload
      };
      setLocalStorageItem('localstate', newState);
      return newState;
    case APP_ACTIONS.CATEGORIES:
      newState = {
        ...state,
        categories: action.payload
      };
      setLocalStorageItem('localstate', newState);
      return newState;
    case APP_ACTIONS.POST_MESSAGE:
      newState = {
        ...state,
        postMessage: action.payload
      };
      setLocalStorageItem('localstate', newState);
      return newState;
    default:
      return state;
  }
};

export const AppContext = createContext<[AppState, React.Dispatch<ActionType>]>(
  [initialAppState, (_: any) => {}]
);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  let localstate: AppState = getLocalStorageItem('localstate');
  const { data } = useCategoryQueryQuery();
  if (localstate === null || localstate === undefined) {
    localstate = initialAppState;
  }
  if (data && data.Category) {
    localstate.categories = data.Category;
    console.log(localstate);
  }

  return (
    <AppContext.Provider value={useReducer(appReducer, localstate)}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
