// import PropTypes from "prop-types";
import { createContext, useContext, useReducer } from 'react';

import { ActionType } from '@/common/constants';

import { setLocalStorageItem } from '../util/LocalStorage';

export const SORT_OPTIONS = [
  { id: 0, name: 'Latest' },
  { id: 1, name: 'Oldest' }
];

export const STATUS_OPTIONS = [
  { id: 0, name: 'New' },
  { id: 1, name: 'In Progress' },
  { id: 2, name: 'Complete' }
];

export interface FilterSortState {
  filterStatus: [{ id: number; name: string }];
  filterCategories: string[];
  filterLocation: {
    addressValue: string;
    lat: number;
    lng: number;
  };
  sortValue: string;
}
let initialFilterSortState: FilterSortState = {
  filterStatus: [STATUS_OPTIONS[0]],
  filterCategories: [],
  filterLocation: {
    addressValue: '',
    lat: 0,
    lng: 0
  },
  sortValue: SORT_OPTIONS[0].name
};

export interface FilterSortContextProps {
  filterSortContextState: FilterSortState;
}
export const FILTERSORT_ACTIONS = {
  FILTER_STATUS: 'FILTER_STATUS',
  FILTER_LOCATION: 'FILTER_LOCATION',
  FILTER_CATEGORY: 'FILTER_CATEGORY',
  SORT_VALUE: 'SORT_VALUE'
};

export { initialFilterSortState };

const filterSortReducer: React.Reducer<FilterSortState, ActionType> = (
  state: FilterSortState = initialFilterSortState,
  action: ActionType
): FilterSortState => {
  let newState;
  switch (action.type) {
    case FILTERSORT_ACTIONS.FILTER_STATUS:
      newState = {
        ...state,
        filterStatus: action.payload
      };
      setLocalStorageItem('filterSortState', newState);
      return newState;
    case FILTERSORT_ACTIONS.FILTER_LOCATION:
      newState = {
        ...state,
        filterLocation: action.payload
      };
      setLocalStorageItem('filterSortState', newState);
      return newState;
    case FILTERSORT_ACTIONS.FILTER_CATEGORY:
      newState = {
        ...state,
        filterCategories: action.payload
      };
      setLocalStorageItem('filterSortState', newState);
      return newState;
    case FILTERSORT_ACTIONS.SORT_VALUE:
      newState = {
        ...state,
        sortValue: action.payload
      };
      setLocalStorageItem('filterSortState', newState);
      return newState;
    default:
      return state;
  }
};

export const FilterSortContext = createContext<
  [FilterSortState, React.Dispatch<ActionType>]
>([initialFilterSortState, (_: any) => {}]);

export const FilterSortProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const filterSortState = initialFilterSortState;
  return (
    <FilterSortContext.Provider
      value={useReducer(filterSortReducer, filterSortState)}
    >
      {children}
    </FilterSortContext.Provider>
  );
};

export const useFilterSortContext = () => useContext(FilterSortContext);
