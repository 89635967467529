export const getLocalStorageItem = (key: string) => {
  try {
    const serialisedValue = localStorage.getItem(key);
    if(serialisedValue === null) {
      return undefined;
    }
    return JSON.parse(serialisedValue);
  } catch (e) {
    return undefined;
  }
}

export const setLocalStorageItem = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    // ignore it
  }
}

export const removeLocalStorageItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // ignore it
  }
}